import React, { FC, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Scrollbar } from 'swiper/modules';
// actions
import englishText from 'languages/english';
import getLocalizeText from 'utils/getLocalizeText';
import { disableScrolling, formatCurrency } from 'utils/functions';
import { downloadFile, openInNewTab, propertyOptions } from 'utils';
import { HttpService, API_CONFIG } from 'services';
// components
import ObjectGallery from '../components/popups/objectGallery';
import ImageGallary from '../components/popups/imageGallary';
import MapPopup from '../components/popups/mapPopup';
import ObjectDescription from '../components/objectDescription';
import ObjectLocation from '../components/objectLocation';
import ContactPopup from '../components/popups/contactPopup';
import CustomSpinner from 'components/commonComponents/customSpinner';
import ObjectUserDetail from '../components/objectUserDetail';
import { isPlot } from 'components/finder/utils/commonFunctions';
import { DownLoadIcon } from 'components/commonComponents/icons';
// import { ClosePopup } from 'components/commonComponents/icons';
// import Modal from 'components/modal';
import { userRole } from 'components/profile/utils/constants';
import tipTile from 'assets/images/placeholder.png';
import plotImage from 'assets/images/plot-image.png';
// style files
import '../../finder/dashboard.scss';
import '../objectDetail.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'swiper/css'; // Import the main Swiper styles
import 'swiper/css/scrollbar'; // Import the scrollbar styles

//interface
import * as objectDetailsInterface from '../interface/objectDetails';

let id = '';
let realtorPage = false;

const ObjectDetails: FC<objectDetailsInterface.IObjectDetailsProps> = (props) => {
	// const navigate = useNavigate();
	SwiperCore.use([Scrollbar]);

	const [propertyDetail, setPropertyDetail] = useState<any>({});
	const [imgList, setImgList] = useState([]);
	const [objectDetails, setObjectDetails] = useState({});
	const [startIndex, setStartIndex] = useState(0);
	const [showSpinner, setShowSpinner] = useState(true);
	const [showMapPopup, setShowMapPopup] = useState(false);
	const [showReadMore, setShowReadMore] = useState(false);
	const [propertyInfoLoading, setPropertyInfoLoading] = useState(false);
	const [showContactPopup, setShowContactPopup] = useState(false);
	const [showGallary, setShowGallary] = useState(false);
	const [objectImageData, setObjectImageData] = useState([]);

	useEffect(() => {
		getID();
		if (window.location.pathname.includes('realtor')) {
			// You may want to use a state variable for realtorPage, but for simplicity, I'll leave it as is.
			realtorPage = true;
		}
		getPropertyInfo(id);
	}, [props.objId]);

	const getID = () => {
		const { objId } = props;
		const currentURL = window.location.href;
		const urlSegments = currentURL.split('/');
		const currentId = urlSegments[urlSegments.length - 1];
		id = currentId ? currentId : objId || '';
	};

	const setStateForObject = (name: string, value: boolean) => {
		if (name === 'showReadMore') {
			setShowReadMore(value as boolean);
		} else if (name === 'showMapPopup') {
			setShowMapPopup(true);
		}
	};

	const getPropertyInfo = (id: string) => {
		setPropertyInfoLoading(true);
		const url = !realtorPage ? `${API_CONFIG.path.objectDetails}/${id}` : `${API_CONFIG.path.userProperty}`;
		HttpService.get(url, {})
			.then((response) => {
				setPropertyInfoLoading(false);
				setPropertyDetail(response.data);
				setImagesAndDetails(response.data);
				setShowSpinner(false);
			})
			.catch((err) => {
				setShowSpinner(false);
				setPropertyInfoLoading(false);
				console.log(err);
			});
	};

	//set images for slider and floorplan and other object details
	const setImagesAndDetails = (list: { objectType: string; objectPictures?: any[]; objectDetails?: any[] }) => {
		const defaultImage = isPlot(list.objectType) ? plotImage : tipTile;
		let imgList: {}[] = [];
		let objectDetails: { [key: string]: string[] } = {};
		let objectImage = [defaultImage];

		if (list.objectPictures && list.objectPictures.length > 0) {
			list.objectPictures.forEach((element: any) => {
				const images = {
					original: element
				};
				imgList.push(images);
			});
			objectImage = [...list.objectPictures];
		} else {
			imgList.push({ original: defaultImage });
		}
		list.objectDetails &&
			list.objectDetails.forEach((ob: any) => {
				let title: string = '';
				if (ob.title === 'Garage/Parking space') {
					title = 'Garage';
				} else {
					title = ob.title.replace(/\s+/g, '_');
				}
				objectDetails[title] = ob.value;
			});

		setObjectDetails(objectDetails);
		setImgList(imgList as any);
		setObjectImageData(objectImage as []);
		setShowSpinner(false);
	};

	// toggle image gallary
	const toggleImageGallary = (value: boolean) => {
		setShowGallary(value);
		if (!value) {
			setStartIndex(0);
		}
		disableScrolling(value);
	};

	const handelPopupContact = () => {
		setShowContactPopup(true);
	};
	const getOwnerDetails = (propertyDetail: any) => {
		const { user, realtor, providerContactName, providerEmailAddress, providerContactNumber } = propertyDetail;
		if (user) {
			return {
				...user,
				contactEmail: user.email,
				contactNumber: user.phone,
				websiteUrl: `<a href='https://www.immoweb.de' target='_blank'>https://www.immoweb.de</a>`
			};
		} else if (realtor) {
			const imprint = realtor.website_url || 'https://www.immoweb.de';
			return {
				companyName: realtor.provider_company_name || '',
				gender: '',
				firstName: providerContactName,
				lastName: '',
				postalCode: realtor.postal_code,
				city: realtor.realtor_city,
				websiteUrl: `<a href='${imprint}' target='_blank'>${imprint}</a>`,
				taxIdentifier: '',
				imprint: realtor.imprint || '',
				roleName: userRole.professionalBroker,
				contactEmail: providerEmailAddress || '',
				impressumWebsiteUrl: realtor.website_url || '',
				contactNumber: providerContactNumber || user?.phone || ''
			};
		}
	};
	const objectDetail = [
		{
			name: 'Standort',
			value: propertyDetail.adrStreet
		},
		{
			name: 'Fläche',
			value: `${
				propertyDetail.areaLiving
					? propertyDetail.areaLiving.toString().replace(',', '.') + ' m²'
					: propertyDetail.areaPlot
					? propertyDetail.areaPlot + ' m²'
					: '-'
			}`
		},
		{
			name: 'Zimmer',
			value: `${propertyDetail.propRoomsAmount ? propertyDetail.propRoomsAmount : '-'}`
		},
		{
			name: 'Baujahr',
			value: `${propertyDetail.yearConstructed || '-'}`
		},
		{
			name: 'Preis pro m2',
			value: `${propertyDetail.pricePerSqm || '-'}`
		},
		{
			name: 'Provision für Käufer',
			value: propertyDetail.costsCommissionPercentShow
				? `${propertyDetail.costsCommissionPercentShow}% inkl. gesetzl. Mwst.`
				: propertyDetail.costsCommissionShow || propertyDetail.costsCommissionAmountShow || '-'
		},
		// {
		// 	name: 'Amount',
		// 	value: `${propertyDetail.costsCommissionAmount || '-'}`
		// },
		{
			name: propertyDetail.marketingType === propertyOptions.RENT ? 'Mietpreis' : 'Kaufpreis',
			value: `${
				propertyDetail.formatedPrice || propertyDetail.totalRent
					? `${propertyDetail.formatedPrice || `${formatCurrency(propertyDetail.totalRent)}`}`
					: '-'
			}`
		}
	];

	const getExtras = () => {
		let array = [];
		if (propertyDetail.tags && propertyDetail.tags.length > 0) {
			const tags = propertyDetail.tags.map((tag: string, ind: number) => <p key={ind}>{tag}</p>);
			array.push(tags);
		}
		if (
			propertyDetail.energyCertificate &&
			propertyDetail.energyCertificate.length > 0 &&
			propertyDetail.energyCertificate[0]
		) {
			const energyCertificate = propertyDetail.energyCertificate.map((link: string, i: number) => (
				<button
					key={`e_${i}`}
					onClick={(e) => {
						e.stopPropagation();
						downloadFile(link);
					}}
				>
					Energieausweis {i > 0 && ` ${i}`} {` `}
					<DownLoadIcon className='small-icon p-l-15' />
				</button>
			));
			array.push(...energyCertificate);
		}
		if (
			propertyDetail.propFloorPlan &&
			propertyDetail.propFloorPlan.length > 0 &&
			propertyDetail.propFloorPlan[0]
		) {
			const propFloorPlan = propertyDetail.propFloorPlan.map((link: string, i: number) => (
				<button
					className='ml--15'
					key={`f_${i}`}
					onClick={(e) => {
						e.stopPropagation();
						downloadFile(link);
					}}
				>
					Grundriss {i > 0 && ` ${i}`} {` `}
					<DownLoadIcon className='small-icon p-l-15' />
				</button>
			));
			array.push(...propFloorPlan);
		}

		if (array.length <= 0) {
			array.push(
				<h2>
					<p>{getLocalizeText(englishText.NO_EXTRA)}</p>
				</h2>
			);
		}
		return array;
	};
	return (
		<div className='object-detail'>
			<hr className='contact-frame'></hr>
			<div className='container'>
				{propertyDetail.objectPictures ? (
					<ObjectGallery
						objectPictures={objectImageData}
						openGallary={(startIndex) => {
							setStartIndex(startIndex as number);
							toggleImageGallary(true);
						}}
						objectType={propertyDetail.object_type}
						startIndex={startIndex}
					/>
				) : showSpinner ? (
					<div className='object-image-gallery'>
						<div className='text-center'>
							{[1, 2, 3].map((count) => (
								<CustomSpinner key={count} className='spin-img' animation='grow' />
							))}
						</div>
					</div>
				) : (
					<div className='disable-object-container'>{getLocalizeText(englishText.PROPERTY_DISABLED)}</div>
				)}

				{showGallary && (
					<div className={`model-wrapper show`}>
						<ImageGallary
							imgList={imgList}
							closeGallery={() => toggleImageGallary(false)}
							startIndex={startIndex}
						/>
					</div>
				)}
				{showMapPopup && propertyDetail && propertyDetail.location && (
					<div className={`model-wrapper ${showMapPopup ? 'show' : ''}`}>
						<MapPopup
							propertyDetail={propertyDetail}
							openMap={() => setShowMapPopup(true)}
							closeMap={() => setShowMapPopup(false)}
						/>
					</div>
				)}
				{
					showContactPopup && (
						// (localStorage.getItem('token') ? (
						<div className={`model-wrapper show contact-popup-wrapper`}>
							<ContactPopup
								closeContactPopup={() => setShowContactPopup(false)}
								propertyDetail={propertyDetail}
								objectId={id}
							/>
						</div>
					)
					// ) : (
					// 	<Modal
					// 		closeModal={() => setShowContactPopup(false)}
					// 		className={'user-register'}
					// 		closeOnOutsideClick={false}
					// 	>
					// 		<div className='contact-login-popup position-relative'>
					// 			<div
					// 				className='position-absolute close-icon cursor-pointer'
					// 				onClick={() => setShowContactPopup(false)}
					// 			>
					// 				<ClosePopup />
					// 			</div>
					// 			<p>
					// 				Bitte anmelden, um den Verkäufer zu kontaktieren. Diese Funktion steht nur
					// 				registrierten Benutzern zur Verfügung.
					// 			</p>
					// 			<p>
					// 				Melden Sie sich an und profitieren Sie von unserer großen Auswahl an exklusiven
					// 				Immobilien-Angeboten zum Kaufen oder Mieten. Hier finden Sie sicher, was Sie
					// 				suchen!
					// 			</p>
					// 			<div className='text--center'>
					// 				<button
					// 					className='common-button'
					// 					onClick={() => {
					// 						setShowContactPopup(false);
					// 						navigate({
					// 							search: `?loginRegister`
					// 						});
					// 					}}
					// 				>
					// 					Jetzt anmelden
					// 				</button>
					// 			</div>
					// 		</div>
					// 	</Modal>
					// ))
				}

				{/* Object description sections */}

				<ObjectDescription
					state={{
						propertyDetail: propertyDetail,
						objectDetails: objectDetails,
						showReadMore: showReadMore
					}}
					setStateForObject={setStateForObject}
					propertyInfoLoading={propertyInfoLoading || showSpinner}
					handelPopupContact={handelPopupContact}
				/>
				<div className='extra-section-wrapper'>
					<div className='extra-section'>
						<h2 className='object-score-title'>Extras</h2>
						{getExtras()}
						{/* {propertyDetail.tags && propertyDetail.tags.length > 0 ? (
							propertyDetail.tags.map((tag: string, ind: number) => <p key={ind}>{tag}</p>)
						) : (
							<h2>
								<p>{getLocalizeText(englishText.NO_EXTRA)}</p>
							</h2>
						)} */}
					</div>
				</div>

				{/* Object map | location info | contact info */}
				<ObjectLocation state={{ propertyDetail: propertyDetail }} setStateForObject={setStateForObject} />
				{(propertyDetail.user || propertyDetail.realtor) && (
					<ObjectUserDetail userDetail={getOwnerDetails(propertyDetail)} />
				)}
			</div>
			{/*object page mobile view */}
			<div className='mobile-responsive-object d-none'>
				<div className='object-title'>
					<h1 className='text--center'>
						{propertyDetail.propertyType
							? `${getLocalizeText(propertyDetail.propertyType)} ${
									propertyDetail.adrPostalCode && ' in ' + propertyDetail.adrPostalCode
							  } 
                      ${(propertyDetail.adrQuarter && ', ' + propertyDetail.adrQuarter) || propertyDetail.city}`
							: `${propertyDetail.adrStreet || '-'}`}
					</h1>
				</div>
				<div className='object-detail-image'>
					<Swiper spaceBetween={50} slidesPerView={1} scrollbar={{ draggable: true }}>
						{imgList.length > 0 ? (
							imgList.map((item: { original: string }, index) => (
								<SwiperSlide key={index}>
									<div className='image-wrapper'>
										<img src={item.original} alt={`property-${index}`} key={`property-${index}`} />
									</div>
								</SwiperSlide>
							))
						) : (
							<SwiperSlide>
								<div className='image-wrapper'>
									{propertyDetail.object_type && (
										<img
											src={isPlot(propertyDetail.object_type) ? plotImage : tipTile}
											alt={'property'}
											key={'property'}
										/>
									)}
								</div>
							</SwiperSlide>
						)}
					</Swiper>
				</div>

				{objectDetail.map((item, i) => {
					if (
						propertyDetail.pricePerSqm &&
						propertyDetail.marketingType !== propertyOptions.RENT &&
						item.name === 'Preis pro m2'
					) {
						return;
					}
					return (
						<div className='object-details' key={i}>
							<h1 className='text--center'>{item.name}</h1>
							<p className={`text--center ${item.name === 'Kaufpreis' ? 'price-text' : ''}`}>
								{item.value}
							</p>
						</div>
					);
				})}
				{(propertyDetail.user || propertyDetail.realtor) && (
					<ObjectUserDetail userDetail={getOwnerDetails(propertyDetail)} />
				)}
			</div>
		</div>
	);
};

export default ObjectDetails;
